import React, { useState, useEffect } from "react";
import Link from "next/link";
// Import react scroll
import { Link as LinkScroll } from "react-scroll";
import ButtonOutline from "../misc/ButtonOutline";
import LogoCompany from "../../public/assets/Logo.svg";

const Header = (props) => {
  const [activeLink, setActiveLink] = useState(null);
  const [scrollActive, setScrollActive] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollActive(window.scrollY > 20);
    });
  }, []);
  const navigation = (props.noNavigation == undefined || props.noNavigation == false); 
  return (
    <>
      <header
        className={
          "fixed top-0 w-full  z-30 bg-white-500 transition-all " +
          (scrollActive ? " shadow-md pt-0" : " pt-4")
        }
      >
        <nav className="max-w-screen-xl px-6 sm:px-8 lg:px-16 mx-auto grid grid-flow-col py-3 sm:py-4">
          <div onClick={()=>{
            window.location.href = "/";
          }} className="col-start-1 col-end-2 flex items-center">
            <LogoCompany className="h-8 w-auto" />
          </div>
          {navigation && (
            <ul className="hidden lg:flex col-start-4 col-end-8 text-black-500  items-center">
              <LinkScroll
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                duration={1000}
                onSetActive={() => {
                  setActiveLink("about");
                }}
                className={
                  "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative" +
                  (activeLink === "about"
                    ? " text-orange-500 animation-active "
                    : " text-black-500 hover:text-orange-500 a")
                }
              >
                Sipi
              </LinkScroll>
              <LinkScroll
                activeClass="active"
                to="patient"
                spy={true}
                smooth={true}
                duration={1000}
                onSetActive={() => {
                  setActiveLink("patient");
                }}
                className={
                  "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative" +
                  (activeLink === "patient"
                    ? " text-orange-500 animation-active "
                    : " text-black-500 hover:text-orange-500 ")
                }
              >
                Patiënt
              </LinkScroll>
              <LinkScroll
                activeClass="active"
                to="counselor"
                spy={true}
                smooth={true}
                duration={1000}
                onSetActive={() => {
                  setActiveLink("counselor");
                }}
                className={
                  "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative" +
                  (activeLink === "counselor"
                    ? " text-orange-500 animation-active "
                    : " text-black-500 hover:text-orange-500 ")
                }
              >
                Begeleider
              </LinkScroll>
              <LinkScroll
                activeClass="active"
                to="organisation"
                spy={true}
                smooth={true}
                duration={1000}
                onSetActive={() => {
                  setActiveLink("organisation");
                }}
                className={
                  "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative" +
                  (activeLink === "organisation"
                    ? " text-orange-500 animation-active "
                    : " text-black-500 hover:text-orange-500 ")
                }
              >
                Organisatie
              </LinkScroll>
            </ul>
          )}
          <div className="col-start-10 col-end-12 font-medium flex justify-end items-center">
            {/* <Link href="/">
              <a className="text-black-600 mx-2 sm:mx-4 capitalize tracking-wide hover:text-orange-500 transition-all">
                  Sign In
              </a>
            </Link> */}
            <ButtonOutline href="https://app.sipi.care">
              Webapplicatie
            </ButtonOutline>
          </div>
        </nav>
      </header>
      {/* Mobile Navigation */}
{navigation && (
      <nav className="fixed lg:hidden bottom-0 left-0 right-0 z-20 px-4 sm:px-8 shadow-t ">
        <div className="bg-white-500 sm:px-3">
          <ul className="flex w-full justify-between items-center text-black-500">
            <LinkScroll
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink("about");
              }}
              className={
                "mx-1 sm:mx-2 px-3 sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all " +
                (activeLink === "about"
                  ? "  border-orange-500 text-orange-500"
                  : " border-transparent")
              }
            >
              {/* <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg> */}
              <svg
                width="24"
                height="21"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.29028 0.000200057C8.67636 -0.000332117 9.06247 0.000200057 9.44855 0.00176528C9.98748 0.0371079 10.4075 0.276587 10.7085 0.720201C10.8575 0.954045 10.9399 1.20971 10.9559 1.48716C10.9574 1.86178 10.9579 2.23637 10.9574 2.61099C15.148 2.61045 19.3386 2.61099 23.5293 2.61255C23.7414 2.64113 23.888 2.75433 23.9691 2.95221C24.0318 3.14895 23.9974 3.32636 23.8658 3.48438C23.7694 3.58606 23.6509 3.64291 23.5105 3.65499C19.3261 3.65655 15.1418 3.65709 10.9574 3.65655C10.9579 4.02491 10.9574 4.39324 10.9559 4.7616C10.9241 5.26084 10.7107 5.66101 10.3157 5.96212C10.0588 6.14619 9.77183 6.24637 9.45481 6.26265C9.25656 6.26318 9.05827 6.26368 8.86002 6.26421C8.64919 6.26468 8.43841 6.26312 8.22767 6.25952C7.77792 6.2165 7.4101 6.02188 7.12419 5.67569C6.91427 5.40797 6.80054 5.10329 6.78298 4.7616C6.78141 4.39324 6.78088 4.02491 6.78141 3.65655C4.68402 3.65709 2.58662 3.65655 0.489226 3.65499C0.275073 3.63273 0.12428 3.52367 0.0368777 3.32786C-0.0428546 3.09633 0.00670025 2.89755 0.185574 2.73151C0.266715 2.66539 0.359595 2.62573 0.464183 2.61255C2.56994 2.61099 4.67566 2.61045 6.78141 2.61099C6.78088 2.23637 6.78141 1.86178 6.78298 1.48716C6.81719 1.0164 7.01597 0.632925 7.37932 0.336722C7.64707 0.129018 7.95072 0.016854 8.29028 0.000200057Z"
                  fill="currentColor"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.29575 1.04512C8.67453 1.04459 9.05331 1.04512 9.4321 1.04669C9.66037 1.07571 9.81326 1.19833 9.89071 1.41451C9.90088 1.44716 9.90714 1.48053 9.90949 1.51469C9.91159 2.59259 9.91159 3.67053 9.90949 4.74843C9.88369 4.94365 9.78299 5.08502 9.6074 5.17261C9.55425 5.19555 9.49896 5.21014 9.44149 5.21643C9.05644 5.21853 8.6714 5.21853 8.28635 5.21643C8.07915 5.18344 7.93568 5.07024 7.85592 4.87678C7.84149 4.83515 7.8321 4.79235 7.82774 4.74843C7.82565 3.67053 7.82565 2.59259 7.82774 1.51469C7.8547 1.31628 7.95853 1.17385 8.13922 1.08738C8.19019 1.06735 8.24237 1.05326 8.29575 1.04512Z"
                  fill="#F7931E"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.6692 7.30684C18.0637 7.30631 18.4581 7.30684 18.8526 7.30841C19.3662 7.34315 19.7727 7.56804 20.0719 7.98302C20.2384 8.22603 20.3303 8.49525 20.3473 8.79067C20.3489 9.16529 20.3494 9.53988 20.3489 9.9145C21.4154 9.91296 22.4818 9.91453 23.5482 9.91919C23.7544 9.95535 23.8958 10.0706 23.9724 10.2651C24.0369 10.4859 23.9863 10.6753 23.8206 10.8333C23.742 10.9002 23.6512 10.9409 23.5482 10.9554C22.4818 10.96 21.4154 10.9616 20.3489 10.9601C20.3494 11.3347 20.3489 11.7093 20.3473 12.0839C20.3098 12.577 20.0954 12.972 19.704 13.2688C19.4687 13.4384 19.2058 13.5364 18.9152 13.563C18.6971 13.5666 18.479 13.5681 18.2609 13.5677C18.0396 13.5681 17.8184 13.5666 17.5972 13.563C17.0923 13.504 16.6994 13.2645 16.4186 12.8446C16.2727 12.6122 16.1913 12.3587 16.1745 12.0839C16.1729 11.7093 16.1724 11.3347 16.1729 10.9601C10.943 10.9606 5.71307 10.9601 0.483135 10.9585C0.304074 10.94 0.166836 10.8549 0.0714826 10.7034C-0.00580799 10.5638 -0.0204271 10.4177 0.0276564 10.2651C0.0931452 10.0942 0.213667 9.98309 0.389222 9.93171C0.420057 9.92367 0.451361 9.91844 0.483135 9.91606C5.71307 9.9145 10.943 9.91396 16.1729 9.9145C16.1724 9.53988 16.1729 9.16529 16.1745 8.79067C16.2072 8.33118 16.3987 7.95397 16.7489 7.65902C17.0172 7.44276 17.324 7.32537 17.6692 7.30684Z"
                  fill="currentColor"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.7008 8.34981C18.0744 8.34928 18.448 8.34981 18.8215 8.35137C19.0006 8.37013 19.1378 8.45518 19.2332 8.60651C19.2725 8.67221 19.2954 8.74315 19.3021 8.81938C19.3042 9.89834 19.3042 10.9773 19.3021 12.0562C19.2766 12.2466 19.179 12.3859 19.0094 12.4742C18.9498 12.5019 18.8872 12.5186 18.8215 12.5243C18.448 12.5263 18.0744 12.5263 17.7008 12.5243C17.4937 12.5007 17.3461 12.3948 17.2579 12.2065C17.2385 12.1582 17.226 12.1081 17.2203 12.0562C17.2182 10.9773 17.2182 9.89834 17.2203 8.81938C17.2459 8.62585 17.3456 8.48551 17.5193 8.39833C17.5776 8.37229 17.6381 8.3561 17.7008 8.34981Z"
                  fill="#ED1C24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.09242 14.6096C4.48896 14.609 4.88546 14.6096 5.28199 14.6111C5.80512 14.6459 6.21677 14.876 6.51695 15.3014C6.68199 15.5482 6.7707 15.8206 6.78304 16.1184C6.7846 16.4847 6.78513 16.851 6.7846 17.2172C12.3599 17.2167 17.9352 17.2172 23.5105 17.2188C23.7344 17.2433 23.8883 17.3596 23.9723 17.5678C24.0335 17.772 23.9928 17.9525 23.8502 18.1094C23.7615 18.1973 23.6545 18.2479 23.5293 18.2612C17.9478 18.2628 12.3662 18.2633 6.7846 18.2628C6.78513 18.6301 6.7846 18.9974 6.78304 19.3647C6.75136 19.8733 6.53273 20.2786 6.12721 20.5809C5.87208 20.7595 5.58824 20.8566 5.27573 20.872C4.88965 20.8741 4.50354 20.8741 4.11747 20.872C3.63453 20.8437 3.24166 20.6428 2.93886 20.2694C2.73663 20.0072 2.62706 19.7098 2.61016 19.3772C2.60859 19.0057 2.60806 18.6343 2.60859 18.2628C1.89382 18.2633 1.17902 18.2628 0.464243 18.2612C0.251874 18.2283 0.106308 18.112 0.0275466 17.9122C-0.0364396 17.6969 0.0110492 17.5107 0.169982 17.3534C0.260858 17.2726 0.367293 17.2278 0.489286 17.2188C1.19573 17.2172 1.90215 17.2167 2.60859 17.2172C2.60806 16.8468 2.60859 16.4764 2.61016 16.1059C2.64297 15.6073 2.8574 15.2082 3.25346 14.9085C3.50406 14.7285 3.7837 14.6288 4.09242 14.6096Z"
                  fill="currentColor"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.15457 15.6525C4.51457 15.652 4.87457 15.6525 5.23457 15.6541C5.47655 15.6746 5.63777 15.7993 5.71822 16.0282C5.72649 16.058 5.73275 16.0882 5.73701 16.119C5.7391 17.1979 5.7391 18.2769 5.73701 19.3559C5.70166 19.5988 5.56549 19.7528 5.32848 19.8176C5.30773 19.8214 5.28685 19.8245 5.26587 19.827C4.88499 19.8291 4.50414 19.8291 4.12326 19.827C3.92232 19.8003 3.77882 19.6954 3.69283 19.5124C3.67095 19.4614 3.65736 19.4081 3.65213 19.3527C3.64903 18.2706 3.65007 17.1885 3.65526 16.1065C3.68813 15.914 3.793 15.7767 3.96987 15.6948C4.02944 15.6703 4.09102 15.6562 4.15457 15.6525Z"
                  fill="#39B54A"
                />
              </svg>
              Sipi
            </LinkScroll>
            <LinkScroll
              activeClass="active"
              to="patient"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink("patient");
              }}
              className={
                "mx-1 sm:mx-2 px-3 sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all " +
                (activeLink === "patient"
                  ? "  border-orange-500 text-orange-500"
                  : " border-transparent ")
              }
            >
              {/* <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                />
              </svg> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Outline"
                viewBox="0 0 24 24"
                width="22"
                fill="currentColor"
                height="22"
              >
                <path d="M12,12A6,6,0,1,0,6,6,6.006,6.006,0,0,0,12,12ZM12,2A4,4,0,1,1,8,6,4,4,0,0,1,12,2Z" />
                <path d="M12,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z" />
              </svg>
              Patiënt
            </LinkScroll>
            <LinkScroll
              activeClass="active"
              to="counselor"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink("counselor");
              }}
              className={
                "mx-1 sm:mx-2 px-3 sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all " +
                (activeLink === "counselor"
                  ? "  border-orange-500 text-orange-500"
                  : " border-transparent ")
              }
            >
              <svg
                id="Layer_1"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
              >
                <path d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5zm7.5 17v-.5a7.5 7.5 0 0 0 -15 0v.5a1 1 0 0 0 2 0v-.5a5.5 5.5 0 0 1 11 0v.5a1 1 0 0 0 2 0zm9-5a7 7 0 0 0 -11.667-5.217 1 1 0 1 0 1.334 1.49 5 5 0 0 1 8.333 3.727 1 1 0 0 0 2 0zm-6.5-9a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5z" />
              </svg>
              {/* <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg> */}
              Begeleider
            </LinkScroll>
            <LinkScroll
              activeClass="active"
              to="organisation"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink("organisation");
              }}
              className={
                "mx-1 sm:mx-2 px-3 sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all " +
                (activeLink === "organisation"
                  ? "  border-orange-500 text-orange-500"
                  : " border-transparent ")
              }
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path d="m21.5,6h-1.5v-3.5c0-1.378-1.121-2.5-2.5-2.5H6.5c-1.379,0-2.5,1.122-2.5,2.5v3.5h-1.5c-1.379,0-2.5,1.122-2.5,2.5v15.5h24v-15.5c0-1.378-1.121-2.5-2.5-2.5ZM1,23v-14.5c0-.827.673-1.5,1.5-1.5h1.5v16H1Zm18,0H5V2.5c0-.827.673-1.5,1.5-1.5h11c.827,0,1.5.673,1.5,1.5v20.5Zm4,0h-3V7h1.5c.827,0,1.5.673,1.5,1.5v14.5Zm-15-9h3v1h-3v-1Zm5,0h3v1h-3v-1Zm-5,4h3v1h-3v-1Zm5,0h3v1h-3v-1Zm-.5-10.5v2.5h-1v-2.5h-2.5v-1h2.5v-2.5h1v2.5h2.5v1h-2.5Z" />
                </svg>
              </svg>
              Organisatie
            </LinkScroll>
          </ul>
        </div>
      </nav>
      )}
      {/* End Mobile Navigation */}
    </>
  );
};

export default Header;
