import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import Image from "next/image";
import ButtonOutline from "./misc/ButtonOutline";
import Modal from "./misc/Modal";

export default function MyModal(props) {
  const scaleFactorDumbIosLogo = 1;
  const badgeScaleFactor = 0.4;
  return (
    <Modal {...props}>
      <div className="flex flex-col items-center gap-4">
        <a href="https://play.google.com/store/apps/details?id=com.signalering">
          <Image
            src="/assets/google-play-badge.png"
            alt="google play store logo"
            layout="intrinsic"
            // layout="responsive"
            quality={100}
            height={250 * badgeScaleFactor}
            width={646 * badgeScaleFactor}
          />
        </a>
        <a href="https://apps.apple.com/nl/app/sipi/id1673323648">
          <Image
            src="/assets/ios-badge.png"
            alt="ios store logo"
            layout="intrinsic"
            // layout="responsive"
            quality={100}
            height={250 * badgeScaleFactor}
            width={646 * badgeScaleFactor}
          />
        </a>
        <div className="my-4">
          <ButtonOutline href={"https://app.sipi.care"}>
            Desktop applicatie
          </ButtonOutline>
        </div>
      </div>
    </Modal>
  );
}
