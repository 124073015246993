import React from "react";
import LogoCompany from "../../public/assets/Logo.svg";
import Facebook from "../../public/assets/Icon/facebook.svg";
import Twitter from "../../public/assets/Icon/twitter.svg";
import Instagram from "../../public/assets/Icon/instagram.svg";
import LinkedIn from "../../public/assets/Icon/linkedin.svg";
import ContactInfoModal from "../ContactInfo";
import GoToAppModal from "../GoToAppModal";
const Footer = () => {
  const [contactInfoOpen, setContactInfoOpen] = React.useState(false);
  const [openDownloadModal, setOpenDownloadModal] = React.useState(false);
  return (
    <div className="bg-white-300 pt-44 pb-24">
      <div className="max-w-screen-xl w-full mx-auto px-6 sm:px-8 lg:px-16 grid grid-rows-6 sm:grid-rows-1 grid-flow-row sm:grid-flow-col grid-cols-3 sm:grid-cols-12 gap-4">
        <div className="row-span-2 sm:col-span-4 col-start-1 col-end-4 sm:col-end-5 flex flex-col items-start ">
          <LogoCompany className="h-8 w-auto mb-6" />
          <p className="mb-4">
            <strong className="font-medium">Sipi</strong> is een digitaal vroeg
            signaleringsplan met als doel een crisis te voorkomen. Het is een
            hulpmiddel voor patiënten en hun begeleiders.
          </p>
          <div className="flex w-full mt-2 mb-8 -mx-2">
            {/* <div className="mx-2 bg-white-500 rounded-full items-center justify-center flex p-2 shadow-md">
              <Facebook className="h-6 w-6" />
            </div> */}
            {/* <div className="mx-2 bg-white-500 rounded-full items-center justify-center flex p-2 shadow-md">
              <Twitter className="h-6 w-6" />
            </div> */}
            {/* <div className="mx-2 bg-white-500 rounded-full items-center justify-center flex p-2 shadow-md">
              <Instagram className="h-6 w-6" />
            </div> */}
            <div className="mx-2 bg-white-500 rounded-full items-center justify-center flex p-2 shadow-md">
              <a href="https://www.linkedin.com/company/sipi-digitaal-signaleringsplan">
                <LinkedIn className="h-6 w-6" />
              </a>
            </div>
          </div>
          <p className="text-gray-400">
            ©{new Date().getFullYear()} - eMindBrowser
          </p>
        </div>
        <div className=" row-span-2 sm:col-span-2 sm:col-start-7 sm:col-end-9 flex flex-col">
          <p className="text-black-600 mb-4 font-medium text-lg">Product</p>
          <ul className="text-black-500 ">
            <GoToAppModal
              isOpen={openDownloadModal}
              setIsOpen={setOpenDownloadModal}
            />
            <li
              onClick={() => setOpenDownloadModal(true)}
              className="my-2 hover:text-orange-500 cursor-pointer transition-all"
            >
              Download{" "}
            </li>
            {/* <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Pricing{" "}
            </li> */}
            {/* <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Locations{" "}
            </li> */}
            {/* <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Server{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Countries{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Blog{" "}
            </li> */}
          </ul>
        </div>
        <div className="row-span-2 sm:col-span-2 sm:col-start-9 sm:col-end-11 flex flex-col">
          <p className="text-black-600 mb-4 font-medium text-lg">Info</p>
          <ul className="text-black-500">
            {/* <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              hjhj ?{" "}
            </li> */}
            {/* <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              FAQ{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Tutorials{" "}
            </li> */}
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              <a href="/about">About Us</a>{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              <a href="/privacyagreement">
                Privacy Policy
              </a>{" "}
            </li>
            <li
              className="my-2 hover:text-orange-500 cursor-pointer transition-all"
            >
              <a href="/termsofservice">
                Terms of Service
              </a>{" "}
            </li>
          </ul>
        </div>
        <ContactInfoModal
          isOpen={contactInfoOpen}
          setIsOpen={setContactInfoOpen}
        />
        <div className="row-span-2 sm:col-span-2 sm:col-start-11 sm:col-end-13 flex flex-col">
          <p className="text-black-600 mb-4 font-medium text-lg">Contact</p>
          <ul className="text-black-500">
            <li
              onClick={() => setContactInfoOpen(true)}
              className="my-2 hover:text-orange-500 cursor-pointer transition-all"
            >
              Contacteer ons
            </li>
            {/* <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              mail{" "}
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
